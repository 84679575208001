//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MODEL from '@mixins/model.js';

export default {
    mixins: [MODEL],
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ruleList1: [], // 规则列表
            ruleList2: [], // 规则列表
            productList: [], // 产品列表
        };
    },
    methods: {
        //添加
        add() {
            var obj = {
                productId: '',
                channelRuleId: '',
                settleRuleId: '',

                settleRemark: '',
                otherRemark: '',
            };

            this.model.push(obj);
        },
        //删除
        del(index) {
            return this.model.splice(index, 1);
        },
        // 产品列表
        queryProductList() {
            this.$get(
                `${this.getGetters('indexUrl')}/settle/settProduct/listSelect`,
                (data) => {
                    this.productList = data;
                }
            );
        },
        // 结算规则
        queryRuleList(ruleType = 1) {
            this.$get(
                `${this.getGetters('indexUrl')}/settle/settRule/listSelect`,
                {
                    ruleType,
                },
                (data) => {
                    this[`ruleList${ruleType}`] = data;
                }
            );
        },
    },
    mounted() {
        this.queryProductList();
        this.queryRuleList(1);
        this.queryRuleList(2);

        if (this.disabled) {
            return;
        }

        this.add();
    },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import add from './add.vue';

export default {
    props: [],
    components: { add },
    data: function () {
        return {
            setIsDwadmin: this.$store.getters.user.isDwadmin, //本地存的
            //搜索条件
            pgData: {
                shbj: '',
                dwname: '',
                xzqycode: '',
                refereeusername: '',
            },
            
            tableData: [],

            shbjList: [
                { label: '待审核', value: 0 },
                { label: '审核通过', value: 1 },
                { label: '审核不通过', value: 2 },
                { label: '待提交', value: 9 },
            ],
            formTitle: '',
            dwcode: '',
            dialogAddVisible: false,
            tableHeight: window.innerHeight - 160,
        };
    },
    computed: {},
    watch: {
        dialogAddVisible: function (n, o) {
            if (n == false) {
                this.getparList();
            }
        },
    },

    methods: {
        //搜索
        search: function () {
            this.getparList();
        },
        detaildw: function (row) {
            if (this.getQuery('token')) {
                this.goto({
                    path: './detail',
                    query: {
                        dwcode: row.dwcode,
                        token: this.getQuery('token'),
                    },
                });
            } else {
                this.goto({
                    path: './detail',
                    query: {
                        dwcode: row.dwcode,
                    },
                });
            }
        },
        auditHandler(row) {
            this.$prompt('请填写审核备注', '审核', {
                confirmButtonText: '通过',
                cancelButtonText: '退回',
                distinguishCancelAndClose: true,
                callback: (action, instance) => {
                    if (action === 'close') {
                        return;
                    }

                    var { inputValue } = instance,
                        shbj = 1;

                    if (action === 'cancel') {
                        shbj = 2;
                    }

                    this.$post(
                        `${this.getGetters(
                            'indexUrl'
                        )}/channel/channelInfo/auditPartner`,
                        {
                            dwcode: row.dwcode,
                            shbj,
                            auditremark: inputValue,
                        },
                        () => {
                            this.getparList();
                        },
                        true
                    );
                },
            });
        },

        adddw: function () {
            this.dialogAddVisible = true;
            this.formTitle = '添加渠道';
            this.dwcode = '';
        },
        editdw: function (row) {
            this.dialogAddVisible = true;
            this.formTitle = '编辑渠道';
            this.dwcode = row.dwcode;
        },
        deldw: function (row) {
            var that = this;

            ShowConfirm(
                '是否删除当前渠道',
                'warning',
                function () {
                    that.$delete(
                        that.$store.getters.partnerUrl +
                            '/channelInfo/' +
                            row.dwcode,
                        {},
                        function (data) {
                            ShowMsg('删除成功', 'success');
                            that.getparList();
                        }
                    );
                },
                function () {
                    ShowMsg('已取消删除操作', 'info');
                }
            );
        },

        //获取列表
        getparList: function () {
            this.$get(
                this.$store.getters.partnerUrl + '/channelInfo',
                this.pgData,
                function (data) {
                    this.tableData = data;
                    this.tableHeight = window.innerHeight - 155;

                    this.$nextTick(() => {
                        this.$refs.table.doLayout();
                    });
                }
            );
        },

        //复制
        handleCopy: function (index, row) {
            this.copyData = row.weburl + '?partnerid=' + row.dwcode;
            this.copy(this.copyData);
        },
        copy: function (data) {
            let url = data;
            let oInput = document.createElement('input');

            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            ShowMsg('复制成功', 'success');
            oInput.remove();
        },
    },
    mounted() {
        this.getparList();
        console.log(this.$store.getters.user);
    },
};

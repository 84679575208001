//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SettleSetting from './components/settle-setting.vue';

export default {
    components: {
        SettleSetting,
    },
    props: {},
    data() {
        return {
            dwinfo: {},
            channelProductList: [],
            user: {},
        };
    },
    computed: {
        dwcode: function () {
            return this.getQuery('dwcode');
        },
    },
    watch: {},
    methods: {
        getDetail: function () {
            this.$get(
                this.$store.getters.partnerUrl + '/channelInfo/' + this.dwcode,
                {},
                function (data) {
                    this.dwinfo = data.dwinfo;
                    this.channelProductList = data.channelProductList;

                    if (data.user) {
                        this.user = data.user;
                    }
                }
            );
        },
        downloadFile: function (val) {
            window.open(val);
        },
        goBack: function () {
            this.$router.go(-1);
        },
    },
    mounted: function () {
        this.getDetail();
    },
};

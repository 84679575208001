//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SettleSetting from './components/settle-setting.vue';

export default {
    components: {
        SettleSetting,
    },
    props: {
        dwcode: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            submitLoading: false,
            form: {
                dwinfo: {
                    dwname: '',
                    registertype: '',
                    needinvoice: '1',
                    khsshy: 'other',
                },
                user: {},

                channelProductList: [],

                settSourceList: [],
            },
            typenumber: this.getQuery('token')
                ? this.getQuery('registertype')
                : this.getGetters('user').registertype,

            sshyList: [
                { diccode: 'agent', dicname: '招标代理' },
                { diccode: 'college', dicname: '高校' },
                { diccode: 'energy', dicname: '能源' },
                { diccode: 'municipal', dicname: '市政' },
                { diccode: 'consult', dicname: '咨询公司' },
                { diccode: 'hospital', dicname: '医院' },
                { diccode: 'finance', dicname: '金融' },
                { diccode: 'other', dicname: '其他' },
            ],
        };
    },
    computed: {},
    methods: {
        //获取详情
        queryData: function () {
            if (!this.dwcode) {
                return;
            }

            this.$get(
                `${this.$store.getters.partnerUrl}/channelInfo/${this.dwcode}`,
                function (data) {
                    this.form.dwinfo = data.dwinfo;
                    this.form.settSourceList = data.settSourceList;

                    if (data.channelProductList.length) {
                        this.form.channelProductList = data.channelProductList;
                    }

                    if (data.user) {
                        this.form.user = data.user;
                    }
                }
            );
        },

        submitHandler(shbj = 9, cb) {
            this.form.dwinfo.shbj = shbj;

            cb && cb();
        },

        //提交
        submit: function () {
            var that = this;

            let submitForm = clone(this.form);

            if (!submitForm.channelProductList.length) {
                showMsgBox('结算设置不能为空', 'warning');

                return;
            }

            if (
                submitForm.channelProductList.some((row) => {
                    return !row.productId || !row.settleRemark;
                })
            ) {
                showMsgBox(
                    '结算设置中存在空的“产品名称”或“结算比例备注”，请填写后重新提交',
                    'warning'
                );

                return;
            }

            // if(!submitForm.dwinfo.licensefile || !submitForm.dwinfo.contractfile) return ShowMsg('您有未上传的文件','warning');

            this.submitLoading = true;

            this.$ajax({
                url: '/bhtong/channel/channelInfo',
                type: 'post',
                data: submitForm,
                fztype: true,

                callback: function (data, res) {
                    that.cancle();
                    ShowMsg('提交成功', 'success');
                },

                complete: function () {
                    that.submitLoading = false;
                },
            });
        },

        cancle: function () {
            this.$emit('close');
        },
        getLicensefile: function (val) {
            this.form.dwinfo.licensefile = val.fileUrl;
        },
        getContractfile: function (val) {
            this.form.dwinfo.contractfile = val.fileUrl;
        },
        //社会统一信用代码校验
        CheckSocialCreditCode: function (rule, value, callback) {
            if (!/^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g.test(value)) {
                callback(new Error('统一社会信用代码格式错误'));
            } else {
                callback();
            }
        },
        //获取地址栏
        getLocationBar: function () {
            if (this.getGetters('user').parentcode == 1) {
                this.form.dwinfo.weburl = this.getQuery('token')
                    ? this.getQuery('weburl')
                    : this.getGetters('user').weburl;
            }
        },
    },
    mounted() {
        this.queryData();
        this.getLocationBar();
    },
};
